import React from "react";
import "./Header.css";
import logo from "../img/logo.png";

export default function Header(props) {
  return (
    <div id={props.id}>
      <img id="logo" src={logo} alt="logo" />
    </div>
  );
}
