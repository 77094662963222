import ContactUs from "./Components/ContactUs";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import "./App.css";

function App() {
  return (
    <>
      <Header id="header" />
      <ContactUs id="contact-us" />
      <Footer id="footer" />
    </>
  );
}

export default App;
