import React from "react";
import "./contact.css";

export default function ContactUs(props) {
  return (
    <div id={props.id}>
      <p>
        <h2>Contact Us: </h2>
        Address:
        <br />
        &emsp;&emsp;&emsp;&emsp;&emsp; Commercial Scape LLC.
        <br />
        &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;3390 County Road Z<br />
        &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;Oshkosh, WI 54902
        <br />
        <br />
        Phone:&emsp;&emsp;&ensp;<a href="tel:+19206889135">920-688-9135</a>{" "}
        <br />
        <br />
        Email: &nbsp;&emsp;&emsp;
        <a href="mailto:commercialscape1@gmail.com">
          commercialscape1@gmail.com
        </a>
        <br />
        <br />
        Hours: &emsp;&emsp; 8-5 Mon-Fri CST
        <br />
        <br />
        Coverage:&emsp; Midwest and beyond
      </p>
    </div>
  );
}
